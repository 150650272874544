export const SELECTED_WORKSPACE_ID = 'cvent-workspace-id';
export const SELECTED_ACCOUNT_MAPPING_ID = 'cvent-account-mapping-id';
export const SELECTED_THEME_COOKIE_NAME = 'cvent-developer-portal-theme';
export const COOKIE_NAME_GDPR_ACCEPTED_TIME = 'gdpr-accepted-timestamp';
export const AUTH_FLOW_TYPES = {
  CLIENT_CREDENTIALS: 'client_credentials',
  AUTH_CODE: 'code'
};
export const INVITATION_STATUS = {
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED'
};

/**
 * Keyword to search for in a usage-tier name to identify if it is Cvent's Free tier.
 */
export const FREE_USAGE_TIER_NAME_KEYWORD = 'free';

/**
 * URL to the Rate Limits section of our public API docs.
 */
export const USAGE_TIER_LEARN_MORE_URL = '/documentation#section/Getting-Started/Rate-Limits';

export enum ExperimentNames {
  // this test name is used in unit tests, don't delete
  TEST_TOGGLE_EXPERIMENT = 'test_toggle_experiment',
  TERMINATION_PROTECTION_EXPERIMENT = 'api_platform_dev_portal_client_termination_toggle',
  OKTA_LOGIN_EXPERIMENT = 'api_platform_dev_portal_okta_login'
}

/**
 * For current dev portal experiments, variant id 1 is considered enabled
 */
export const ENABLED_VARIANT_ID = 1;

export const STATE_STORAGE_KEY = 'last-state-token';

/**
 * Cookie name for the session cookie to store the active invitation data in case the logged in developer needs to be redirected
 * to the cognito logout endpoint before redirecting to the signup and the accept page invitation data is lost.
 */
export const ACTIVE_INVITATION_COOKIE_NAME = 'active-invitation';

/**
 * The amount of time that access tokens are valid for when minted by the developer portal
 */
export const ACCESS_TOKEN_TTL_SECONDS = 3_600;
