import { NavProps } from '@cvent/docs';
import { useRouter } from 'next/router';

/**
 * Creates a navigation object that will be used by the Layout component from @cvent/docs.
 *
 * This object should return an empty sidebar and undefined header unless the path from the
 * router starts with '/docs'.
 *
 * @returns The navigation object that will be used for the @cvent/docs Layout component.
 */
export const useDocSiteNavigation = () => {
  const { pathname } = useRouter();

  // if the path isn't /docs, return nothing for this navigation
  if (!pathname.startsWith('/docs')) {
    return undefined;
  }

  // this property describes the side navigation options available when viewing the markdown docs.
  const sideNavigationContent = [
    {
      title: 'Cvent Platform REST API',
      items: [
        {
          url: '/docs/rest-api/overview',
          title: 'Overview'
        },
        {
          url: '/docs/rest-api/concepts',
          title: 'Concepts'
        },
        {
          url: '/docs/rest-api/inviting-your-developers',
          title: 'Inviting Your Developers'
        },
        {
          url: '/docs/rest-api/developer-quickstart',
          title: 'Developer Quickstart'
        },
        {
          url: '/docs/rest-api/api-standards',
          title: 'API Standards'
        },
        {
          url: '/docs/rest-api/using-the-reference',
          title: 'Using the API Reference'
        },
        {
          url: '/docs/rest-api/managing-developers',
          title: 'Managing Developers'
        },
        {
          url: '/docs/rest-api/attendee-activities',
          title: 'Attendee Activities Guide',
          items: [
            {
              url: '/docs/rest-api/attendee-activities/overview',
              title: 'Overview'
            },
            {
              url: '/docs/rest-api/attendee-activities/activity-definitions',
              title: 'Activity Definitions'
            },
            {
              url: '/docs/rest-api/attendee-activities/external-attendee-activities',
              title: 'External Attendee Activities'
            }
          ]
        },
        {
          url: '/docs/rest-api/virtual-events-integration',
          title: 'Virtual Events Integration',
          items: [
            {
              url: '/docs/rest-api/virtual-events-integration/event-integration',
              title: 'Virtual Event Integration'
            },
            {
              url: '/docs/rest-api/virtual-events-integration/session-integration',
              title: 'Virtual Sessions Integration'
            }
          ]
        },
        {
          url: '/docs/rest-api/registration-guide',
          title: 'Registration Guide'
        },
        {
          url: '/docs/rest-api/compliance-guide',
          title: 'Gathering Compliance Data Guide'
        },
        {
          url: '/docs/rest-api/migration-guide',
          title: 'SOAP Migration Guide',
          items: [
            {
              url: '/docs/rest-api/migration-guide/benefits',
              title: 'Key Concepts and Benefits'
            },
            {
              url: '/docs/rest-api/migration-guide/calls-and-methods',
              title: 'Calls and Methods'
            },
            {
              url: '/docs/rest-api/migration-guide/object-mappings',
              title: 'Object Mapping',
              items: [
                {
                  url: 'x',
                  title: 'Contact Objects',
                  items: [
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/contact',
                      title: 'Contact'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/contact-group',
                      title: 'Contact Group'
                    }
                  ]
                },
                {
                  url: 'x',
                  title: 'Event Objects',
                  items: [
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/event',
                      title: 'Event'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/event-parameter',
                      title: 'Event Parameter'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/event-detail',
                      title: 'Event Detail'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/session',
                      title: 'Session'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/invitee',
                      title: 'Invitee'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/registration',
                      title: 'Registration'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/guest',
                      title: 'Guest'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/event-email-history',
                      title: 'EventEmailHistory'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/transaction',
                      title: 'Transaction'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/travel',
                      title: 'Travel'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/budget',
                      title: 'Budget'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/budget-item',
                      title: 'BudgetItem'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/event-question',
                      title: 'EventQuestion'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/speaker',
                      title: 'Speaker'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/table-assignment',
                      title: 'TableAssignment'
                    }
                  ]
                },
                {
                  url: '/docs/rest-api/migration-guide/object-mappings/rfp-objects',
                  title: 'RFP Objects'
                },
                {
                  url: 'x',
                  title: 'Survey Objects',
                  items: [
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/survey',
                      title: 'Survey'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/respondent',
                      title: 'Respondent'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/response',
                      title: 'Response'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/survey-email-history',
                      title: 'SurveyEmailHistory'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/survey-question',
                      title: 'SurveyQuestion'
                    }
                  ]
                },
                {
                  url: 'x',
                  title: 'eMarketing Objects',
                  items: [
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/campaign',
                      title: 'Campaign'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/distribution-list',
                      title: 'Distribution List'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/emarketing-email-history',
                      title: 'EMarketingEmailHistory'
                    }
                  ]
                },
                {
                  url: '/docs/rest-api/migration-guide/object-mappings',
                  title: 'Administration Objects',
                  items: [
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/approver',
                      title: 'Approver'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/user-group',
                      title: 'User Group'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/user-role',
                      title: 'User Role'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/meeting-request',
                      title: 'Meeting Request'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/advanced-logic',
                      title: 'Advanced Logic'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/rate-history',
                      title: 'Rate History'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/user',
                      title: 'User'
                    },
                    {
                      url: '/docs/rest-api/migration-guide/object-mappings/custom-field',
                      title: 'Custom Field'
                    }
                  ]
                }
              ]
            },
            {
              url: '/docs/rest-api/migration-guide/example-workflows',
              title: 'Common Example Workflows'
            },
            {
              url: '/docs/rest-api/migration-guide/faq',
              title: 'FAQ'
            }
          ]
        },
        {
          url: '/docs/rest-api/managing-events-guide',
          title: 'Managing Events Guide'
        },
        {
          url: '/docs/rest-api/bulk-api-user-guide',
          title: 'Bulk API User Guide'
        },
        {
          url: '/docs/rest-api/proposal-guide',
          title: 'Proposal Management Guide'
        },
        {
          url: '/docs/rest-api/user-management',
          title: 'User Management Guide'
        },
        {
          url: '/docs/rest-api/obfuscation-guide',
          title: 'Obfuscation Guide'
        },
        {
          url: '/docs/rest-api/event-travel-guide',
          title: 'Event Travel Guide'
        },
        {
          url: '/docs/rest-api/video-archiving',
          title: 'Video Archiving Guide'
        },
        {
          url: '/docs/rest-api/match-filter',
          title: 'Match Filter Guide'
        },
        {
          url: '/docs/rest-api/changelog',
          title: 'Changelog'
        }
      ]
    },
    {
      title: 'Passkey',
      items: [
        {
          url: '/docs/passkey/REST/overview',
          title: 'Overview'
        },
        {
          url: '/docs/passkey/REST/getting-started',
          title: 'Getting Started'
        },
        {
          url: '/docs/passkey/REST/callbacks',
          title: 'Passkey Callbacks'
        },
        {
          url: '/docs/passkey/REST/migration',
          title: 'Migration'
        },
        {
          url: '/docs/passkey/REST/faq',
          title: 'FAQ'
        }
      ]
    },
    {
      url: '/docs/legacy-api/',
      title: 'Legacy APIs',
      items: [
        {
          title: 'SOAP API',
          items: [
            {
              url: '/docs/legacy-api/soap-api/overview',
              title: 'Overview'
            },
            {
              url: '/docs/legacy-api/soap-api/framework',
              title: 'Framework'
            },
            {
              url: '/docs/legacy-api/soap-api/api-management',
              title: 'API Management'
            },
            {
              url: '/docs/legacy-api/soap-api/error-codes',
              title: 'Error Codes'
            },
            {
              url: '/docs/legacy-api/soap-api/sample-code',
              title: 'Sample Code'
            },
            {
              url: '/docs/legacy-api/soap-api/call-definitions',
              title: 'Call Definitions',
              items: [
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/authentication_call',
                  title: 'Authentication Call',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/authentication_call/overview',
                      title: 'Overview'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/search_and_retrieve_calls',
                  title: 'Search and Retrieve Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/search_and_retrieve_calls/get_updated',
                      title: 'GetUpdated'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/search_and_retrieve_calls/get_deleted',
                      title: 'GetDeleted'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/search_and_retrieve_calls/search',
                      title: 'Search'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/search_and_retrieve_calls/retrieve',
                      title: 'Retrieve'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/metadata_calls',
                  title: 'Metadata Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/metadata_calls/overview',
                      title: 'Overview'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls',
                  title: 'User Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls/create_user',
                      title: 'CreateUser'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls/delete_user',
                      title: 'DeleteUser'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls/update_user',
                      title: 'UpdateUser'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/user_management_calls/manage_user_group',
                      title: 'ManageUserGroup'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/custom_field_management_calls',
                  title: 'Custom Field Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/custom_field_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/custom_field_management_calls/create_custom_field',
                      title: 'CreateCustomField'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/custom_field_management_calls/update_custom_field',
                      title: 'UpdateCustomField'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/custom_field_management_calls/add_advanced_logic',
                      title: 'AddAdvancedLogic'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls',
                  title: 'Address Book Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/create_contact',
                      title: 'CreateContact'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/update_contact',
                      title: 'UpdateContact'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/upsert_contact',
                      title: 'UpsertContact'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/delete_contact',
                      title: 'DeleteContact'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/create_contact_group',
                      title: 'CreateContactGroup'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/manage_contact_group_members',
                      title: 'ManageContactGroupMembers'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/create_distribution_list',
                      title: 'CreateDistributionList'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/address_book_management_calls/manage_distribution_list_members',
                      title: 'ManageDistributionListMembers'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls',
                  title: 'Event Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/create_no_reg_event',
                      title: 'CreateNoRegEvent'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/copy_event',
                      title: 'CopyEvent'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/update_event_parameters',
                      title: 'UpdateEventParameters'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/create_session',
                      title: 'CreateSession'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/event_management_calls/update_session',
                      title: 'UpdateSession'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls',
                  title: 'Invitee Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/send_email',
                      title: 'SendEmail'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/check_in',
                      title: 'CheckIn'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/update_registration_type',
                      title: 'UpdateRegistrationType'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/update_invitee_internal_info',
                      title: 'UpdateInviteeInternalInfo'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/update_guest_info',
                      title: 'UpdateGuestInfo'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/simple_event_registration',
                      title: 'SimpleEventRegistration'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/session_reg_action',
                      title: 'SessionRegAction'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/create_post_event_feedback',
                      title: 'CreatePostEventFeedback'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/create_transaction',
                      title: 'CreateTransaction'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/transfer_invitee',
                      title: 'TransferInvitee'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/invitee_management_calls/validate_invitee',
                      title: 'ValidateInvitee'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls',
                  title: 'Budget Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls/create_budget_item',
                      title: 'CreateBudgetItem'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls/delete_budget_item',
                      title: 'DeleteBudgetItem'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls/create_conversion_rate',
                      title: 'CreateConversionRate'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/budget_management_calls/delete_conversion_rate',
                      title: 'DeleteConversionRate'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls',
                  title: 'Meeting Request Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/create_meeting_request',
                      title: 'CreateMeetingRequest'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/update_meeting_request',
                      title: 'UpdateMeetingRequest'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/create_approver',
                      title: 'CreateApprover'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/update_approver',
                      title: 'UpdateApprover'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/meeting_request_management_calls/delete_approver',
                      title: 'DeleteApprover'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/call-definitions/rfp_management_calls',
                  title: 'RFP Management Calls',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/rfp_management_calls/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/call-definitions/rfp_management_calls/create_rfp',
                      title: 'CreateRFP'
                    }
                  ]
                }
              ]
            },
            {
              url: '/docs/legacy-api/soap-api/object-definitions/',
              title: 'Object Definitions',
              items: [
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/contact_objects',
                  title: 'Contact Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/contact_objects/contact',
                      title: 'Contact'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/contact_objects/contact_group',
                      title: 'ContactGroup'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/api_core_objects',
                  title: 'API Core Objects'
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/event_objects',
                  title: 'Event Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/event',
                      title: 'Event'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/event_parameters',
                      title: 'EventParameters'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/event_detail',
                      title: 'EventDetail'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/session',
                      title: 'Session'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/invitee',
                      title: 'Invitee'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/registration',
                      title: 'Registration'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/guest',
                      title: 'Guest'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/event_email_history',
                      title: 'EventEmailHistory'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/transaction',
                      title: 'Transaction'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/travel',
                      title: 'Travel'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/budget',
                      title: 'Budget'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/budget_item',
                      title: 'BudgetItem'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/event_question',
                      title: 'EventQuestion'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/speaker',
                      title: 'Speaker'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/event_objects/table_assignment',
                      title: 'TableAssignment'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects',
                  title: 'RFP Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/rfp',
                      title: 'RFP'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/proposal',
                      title: 'Proposal'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/supplier',
                      title: 'Supplier'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/supplier_rfp',
                      title: 'SupplierRFP'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/supplier_proposal',
                      title: 'SupplierProposal'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/rfp_objects/rfp_parameters',
                      title: 'RFPParameters'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/survey_objects',
                  title: 'Survey Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/survey_objects/survey',
                      title: 'Survey'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/survey_objects/respondent',
                      title: 'Respondent'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/survey_objects/response',
                      title: 'Response'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/survey_objects/survey_email_history',
                      title: 'SurveyEmailHistory'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/survey_objects/survey_question',
                      title: 'SurveyQuestion'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/cv_search_object',
                  title: 'CVSearchObject'
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/e_marketing_objects',
                  title: 'eMarketing Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/e_marketing_objects/campaign',
                      title: 'Campaign'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/e_marketing_objects/distribution_list',
                      title: 'Distribution List'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/e_marketing_objects/e_marketing_email_history',
                      title: 'EMarketingEmailHistory'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/cv_object',
                  title: 'CvObject'
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/administration_objects',
                  title: 'Administration Objects',
                  items: [
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/approver',
                      title: 'Approver'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/user_group',
                      title: 'UserGroup'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/user_role',
                      title: 'UserRole'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/meeting_request',
                      title: 'MeetingRequest'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/advanced_logic',
                      title: 'AdvancedLogic'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/rate_history',
                      title: 'RateHistory'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/user',
                      title: 'User'
                    },
                    {
                      url: '/docs/legacy-api/soap-api/object-definitions/administration_objects/custom_field',
                      title: 'CustomField'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/soap-api/object-definitions/cvent-objects',
                  title: 'Cvent Objects'
                }
              ]
            },
            {
              url: '/docs/legacy-api/soap-api/soap-certificates',
              title: 'SOAP API Certificates'
            },
            {
              url: '/docs/legacy-api/soap-api/changelog',
              title: 'Changelog'
            }
          ]
        },
        {
          url: '/docs/legacy-api/reglink/',
          title: 'RegLink APIs',
          items: [
            {
              url: '/docs/legacy-api/reglink/overview',
              title: 'Overview'
            },
            {
              url: '/docs/legacy-api/reglink/getting-started',
              title: 'Getting Started'
            },
            {
              url: '/docs/legacy-api/reglink/definitions',
              title: 'Definitions of Terms'
            },
            {
              url: '/docs/legacy-api/reglink/methods',
              title: 'Integration Methods',
              items: [
                {
                  url: '/docs/legacy-api/reglink/methods/passkey-rest',
                  title: 'RESTful APIs'
                },
                {
                  url: '/docs/legacy-api/reglink/methods/passkey-browser',
                  title: 'Browser-based (Legacy)',
                  items: [
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-browser/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-browser/express',
                      title: 'RegExpress'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-browser/basic',
                      title: 'RegLink Basic'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/reglink/methods/passkey-soap',
                  title: 'Web Services / SOAP API (Legacy)',
                  items: [
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-soap/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-soap/advanced',
                      title: 'RegLink Advanced',
                      items: [
                        {
                          url: '/docs/legacy-api/reglink/methods/passkey-soap/advanced/overview',
                          title: 'Overview'
                        },
                        {
                          url: '/docs/legacy-api/reglink/methods/passkey-soap/advanced/availability-messages',
                          title: 'Availability Messages'
                        },
                        {
                          url: '/docs/legacy-api/reglink/methods/passkey-soap/advanced/bridge-messages',
                          title: 'Bridge Messages'
                        },
                        {
                          url: '/docs/legacy-api/reglink/methods/passkey-soap/advanced/reservation-messsages',
                          title: 'Reservation Messages'
                        }
                      ]
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/passkey-soap/basic-and-express',
                      title: 'RegLink Basic and RegExpress ',
                      items: [
                        {
                          url: '/docs/legacy-api/reglink/methods/passkey-soap/basic-and-express/bridge-record',
                          title: 'Create and Manage Bridge Record (Attendee Information)'
                        }
                      ]
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/reglink/methods/callback-service',
                  title: 'RegLink Callback Service',
                  items: [
                    {
                      url: '/docs/legacy-api/reglink/methods/callback-service/overview',
                      title: 'Overview'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/callback-service/post-to-url',
                      title: 'POST to a URL'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/callback-service/tags',
                      title: 'Callback Service Tags'
                    },
                    {
                      url: '/docs/legacy-api/reglink/methods/callback-service/existing-reservations',
                      title: 'Accessing Existing Reservation'
                    }
                  ]
                }
              ]
            },
            {
              url: '/docs/legacy-api/reglink/supported-features/',
              title: 'Supported Features'
            },
            {
              url: '/docs/legacy-api/reglink/auth-headers-endpoints-ip/',
              title: 'Authentication, Header Details, Endpoints, and IP Addresses',
              items: [
                {
                  url: '/docs/legacy-api/reglink/auth-headers-endpoints-ip/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/reglink/auth-headers-endpoints-ip/auth-and-header-details',
                  title: 'Authentication and Header Details'
                },
                {
                  url: '/docs/legacy-api/reglink/auth-headers-endpoints-ip/endpoints',
                  title: 'End Points (WSDL)'
                },
                {
                  url: '/docs/legacy-api/reglink/auth-headers-endpoints-ip/endpoints-and-ip',
                  title: 'Endpoints and IP Addresses'
                }
              ]
            },
            {
              url: '/docs/legacy-api/reglink/field-mapping',
              title: 'Field Mappings',
              items: [
                {
                  url: '/docs/legacy-api/reglink/field-mapping/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/regexpress',
                  title: 'RegExpress'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/create-bridge-mapping-browser-based-and-soap',
                  title: 'Create Bridge Mapping (Browser-Based and SOAP)'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/availability-message-request-soap-only',
                  title: 'Availability Message Request (SOAP Only)'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/availability-message-response',
                  title: 'Availability Message Response'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/create-reservation-soap-only',
                  title: 'Create Reservation (SOAP Only)'
                },
                {
                  url: '/docs/legacy-api/reglink/field-mapping/get-reservation-response-message-soap-only',
                  title: 'Get Reservation Response Message (SOAP Only)'
                }
              ]
            },
            {
              url: '/docs/legacy-api/reglink/samples',
              title: 'Message Sample Library',
              items: [
                {
                  url: '/docs/legacy-api/reglink/samples/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/reglink/samples/basic-and-express',
                  title: 'RegLink Basic and RegExpress',
                  items: [
                    {
                      url: '/docs/legacy-api/reglink/samples/basic-and-express/create-bridge',
                      title: 'Create Bridge'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/basic-and-express/modify-bridge',
                      title: 'Modify Bridge'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/basic-and-express/cancel-bridge',
                      title: 'Cancel Bridge'
                    }
                  ]
                },
                {
                  url: '/docs/legacy-api/reglink/samples/advanced',
                  title: 'RegLink Advanced',
                  items: [
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/get-bridge',
                      title: 'Get Bridge'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/get-housing-availability',
                      title: 'Get Housing Availability'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/get-housing-details-by-event',
                      title: 'Get Housing Details by Event'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/create-from-bridge',
                      title: 'Create From Bridge'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/get-reservation',
                      title: 'Get Reservation'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/create-reservation',
                      title: 'Create Reservation'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/modify-reservation',
                      title: 'Modify Reservation'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/cancel-reservation',
                      title: 'Cancel Reservation'
                    },
                    {
                      url: '/docs/legacy-api/reglink/samples/advanced/get-status',
                      title: 'Get Status'
                    }
                  ]
                }
              ]
            },
            {
              url: '/docs/legacy-api/reglink/next-steps',
              title: 'Next Steps'
            },
            {
              url: '/docs/legacy-api/reglink/faq',
              title: 'FAQ'
            },
            {
              url: '/docs/legacy-api/reglink/migration',
              title: 'Migration Guide to REST'
            }
          ]
        },
        {
          url: '/docs/legacy-api/csn/',
          title: 'Cvent Supplier Network API',
          items: [
            {
              url: '/docs/legacy-api/csn/overview',
              title: 'Overview'
            },
            {
              url: '/docs/legacy-api/csn/planner-guide',
              title: 'User Guide for Planners',
              items: [
                {
                  url: '/docs/legacy-api/csn/planner-guide/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/authentication',
                  title: 'Authentication'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/push-notification-system',
                  title: 'Push Notification System (PNS)'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/rfp-objects',
                  title: 'RFP Objects'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/searching-rfps',
                  title: 'Searching RFPs'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/creating-rfps',
                  title: 'Creating RFPs'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/rfp-response',
                  title: 'RFP Response Objects'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/message-object',
                  title: 'Message Objects'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/contract-objects',
                  title: 'Contract Objects'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/venue-objects',
                  title: 'Venue Objects'
                },
                {
                  url: '/docs/legacy-api/csn/planner-guide/appendix',
                  title: 'Appendix'
                }
              ]
            },
            {
              url: '/docs/legacy-api/csn/supplier-guide',
              title: 'User Guide for Suppliers',
              items: [
                {
                  url: '/docs/legacy-api/csn/supplier-guide/overview',
                  title: 'Overview'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/authentication',
                  title: 'Authentication'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/push-notification-system',
                  title: 'Push Notification System (PNS)'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/rfp-objects',
                  title: 'RFP Objects'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/rfp-response',
                  title: 'RFP Response Objects'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/creating-proposal',
                  title: 'Creating a Proposal through the API'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/submitting-responses',
                  title: 'Submitting Additional Types of RFP Responses Through the API'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/message-objects',
                  title: 'Message Objects'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/venue-objects',
                  title: 'Venue Objects'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/cvb-functionality',
                  title: 'CVB Specific Functionality'
                },
                {
                  url: '/docs/legacy-api/csn/supplier-guide/appendix',
                  title: 'Appendix'
                }
              ]
            },
            {
              url: '/docs/legacy-api/csn/planner-json-schema-examples',
              title: 'Planner JSON Schemas & Examples'
            },
            {
              url: '/docs/legacy-api/csn/venue-json-schema-examples',
              title: 'Venue JSON Schema & Examples'
            },
            {
              url: '/docs/legacy-api/csn/supplier-json-schema-examples',
              title: 'Supplier JSON Schema & Examples'
            }
          ]
        },
        {
          url: '/docs/legacy-api/badgekit/',
          title: 'Cvent BadgeKit API',
          items: [
            {
              url: '/docs/legacy-api/badgekit/overview',
              title: 'Overview'
            },
            {
              url: '/docs/legacy-api/badgekit/Authentication',
              title: 'Authentication'
            },
            {
              url: '/docs/legacy-api/badgekit/Setting_Up_Your_Scanning_Software',
              title: 'Setting Up Your Scanning Software'
            },
            {
              url: '/docs/legacy-api/badgekit/Attendees_Object',
              title: 'Attendees Object'
            }
          ]
        }
      ]
    },
    {
      url: '/docs/cid',
      title: 'Custom Identity Confirmation',
      items: [
        {
          url: '/docs/cid/overview',
          title: 'Overview'
        },
        {
          url: '/docs/cid/attendee-identification-service',
          title: 'Attendee Identification Service (REST)'
        },
        {
          url: '/docs/cid/legacy-cid',
          title: 'Legacy Custom Identity Confirmation (SOAP)'
        }
      ]
    },
    {
      url: '/docs/custom-widgets/',
      title: 'Custom Widgets',
      items: [
        {
          url: '/docs/custom-widgets/overview',
          title: 'Overview'
        },
        {
          title: 'The Custom Widget SDK',
          items: [
            {
              url: '/docs/custom-widgets/sdk/retrieving-event-objects',
              title: 'Retrieving Event Objects'
            },
            {
              url: '/docs/custom-widgets/sdk/registration-methods',
              title: 'Registration Methods'
            },
            {
              url: '/docs/custom-widgets/sdk/navigation',
              title: 'Navigation'
            },
            {
              url: '/docs/custom-widgets/sdk/supported-expressions',
              title: 'Supported Expressions'
            }
          ]
        },
        {
          url: '/docs/custom-widgets/publishing',
          title: 'Custom Widget Publishing'
        },
        {
          url: '/docs/custom-widgets/required-assets',
          title: 'Required Assets',
          items: [
            {
              url: '/docs/custom-widgets/required-assets/custom-widget-configuration',
              title: 'The Configuration File'
            },
            {
              url: '/docs/custom-widgets/required-assets/custom-widget-element',
              title: 'The Widget Element'
            },
            {
              url: '/docs/custom-widgets/required-assets/custom-widget-editor',
              title: 'The Editor Element'
            }
          ]
        },
        {
          url: '/docs/custom-widgets/examples',
          title: 'Examples',
          items: [
            {
              url: '/docs/custom-widgets/examples/featured-session-widget',
              title: 'Featured Sessions'
            },
            {
              url: '/docs/custom-widgets/examples/registration-widget',
              title: 'Session Registration'
            }
          ]
        }
      ]
    },
    {
      url: '/docs/events-plus',
      title: 'Events+',
      items: [
        {
          url: '/docs/events-plus/custom-header',
          title: 'Events+ Custom Header'
        }
      ]
    },
    {
      url: '/docs/http-post',
      title: 'HTTP POST',
      items: [
        {
          url: '/docs/http-post/overview',
          title: 'Overview'
        },
        {
          url: '/docs/http-post/events',
          title: 'HTTP POST Process for Events',
          items: [
            {
              url: '/docs/http-post/events/overview',
              title: 'Overview'
            },
            {
              url: '/docs/http-post/events/process',
              title: 'HTTP POST Process'
            },
            {
              url: '/docs/http-post/events/form-fields',
              title: 'Form Fields'
            },
            {
              url: '/docs/http-post/events/custom-authentication-url',
              title: 'Custom Authentication URL'
            },
            {
              url: '/docs/http-post/events/process-visualization',
              title: 'Process Visualization'
            }
          ]
        },
        {
          url: '/docs/http-post/secure-post',
          title: 'HTTP Secure POST Process',
          items: [
            {
              url: '/docs/http-post/secure-post/overview',
              title: 'Overview'
            },
            {
              url: '/docs/http-post/secure-post/generate-rsa-signature',
              title: 'Generating and Verifying an RSA Digital Signature'
            },
            {
              url: '/docs/http-post/secure-post/generate-key-pair',
              title: 'Generating Private Key & Public Key Pair'
            },
            {
              url: '/docs/http-post/secure-post/testing-secure-post',
              title: 'Testing Your HTTP Secure POST Implementation'
            },
            {
              url: '/docs/http-post/secure-post/troubleshooting',
              title: 'Troubleshooting Errors'
            }
          ]
        }
      ]
    },
    {
      url: '/docs/dfi/',
      title: 'Meeting Request Form Dynamic Field Integration (DFI)',
      items: [
        {
          url: '/docs/dfi/overview',
          title: 'Overview'
        },
        {
          url: '/docs/dfi/setting-up-the-integration',
          title: 'Setting Up The Integration'
        },
        {
          url: '/docs/dfi/configuring-the-integration',
          title: 'Configuring the Integration'
        },
        {
          url: '/docs/dfi/testing-field-mappings',
          title: 'Testing Field Mappings'
        },
        {
          url: '/docs/dfi/dynamic-field-set',
          title: 'Dynamic Field Set'
        },
        {
          url: '/docs/dfi/configuring-meeting-request-form',
          title: 'Configuring Meeting Request Form'
        },
        {
          url: '/docs/dfi/faq',
          title: 'Frequently Asked Questions'
        }
      ]
    },
    {
      url: '/docs/white-label',
      title: 'White Label',
      items: [
        {
          url: '/docs/white-label/overview',
          title: 'Overview'
        },
        {
          url: '/docs/white-label/widget-api-deployment',
          title: 'Widget API Deployment'
        },
        {
          url: '/docs/white-label/sending-rfp-multiple-venues',
          title: 'Sending an RFP to Multiple Venues at a Time'
        },
        {
          url: '/docs/white-label/sample-code',
          title: 'Sample Embed Code'
        },
        {
          url: '/docs/white-label/custom-theme-branding',
          title: 'Custom Theming and Branding'
        },
        {
          url: '/docs/white-label/analytics',
          title: 'Analytics'
        }
      ]
    },
    {
      url: '/docs/wl-ibk/',
      title: 'WhiteLabel Instant Book Wigdet',
      items: [
        {
          url: '/docs/wl-ibk/overview',
          title: 'Overview'
        },
        {
          url: '/docs/wl-ibk/deployment',
          title: 'Widget API Deployment'
        },
        {
          url: '/docs/wl-ibk/troubleshooting',
          title: 'Errors and Troubleshooting'
        },
        {
          url: '/docs/wl-ibk/custom-theming',
          title: 'Custom Theming and Branding'
        }
      ]
    },
    {
      url: '/docs/sso/',
      title: 'Single Sign-On',
      items: [
        {
          url: '/docs/sso/overview',
          title: 'Overview'
        },
        {
          url: '/docs/sso/planner-login',
          title: 'Event Planner'
        },
        {
          url: '/docs/sso/passkey-planner',
          title: 'Passkey Planner'
        },
        {
          url: '/docs/sso/portals',
          title: 'Portals'
        },
        {
          url: '/docs/sso/event-attendee-hub',
          title: 'Registration & Attendee Hub'
        },
        {
          url: '/docs/sso/events-plus',
          title: 'Events+ Hub'
        },
        {
          url: '/docs/sso/access-portal',
          title: 'Access Portals'
        },
        {
          url: '/docs/sso/contact-website',
          title: 'Contact Websites'
        },
        {
          url: '/docs/sso/meeting-request-form',
          title: 'Meeting Request Forms'
        },
        {
          url: '/docs/sso/onarrival',
          title: 'OnArrival Planner'
        },
        {
          url: '/docs/sso/troubleshooting',
          title: 'Troubleshooting'
        }
      ]
    },
    {
      url: '/docs/cvent-salesforce-app',
      title: 'Cvent Salesforce App',
      items: [
        {
          url: '/docs/cvent-salesforce-app/release-notes',
          title: 'Release Notes'
        },
        {
          title: 'Overview and Requirements',
          url: '/docs/cvent-salesforce-app/overview'
        },
        {
          title: 'Define your Business Requirements',
          url: '/docs/cvent-salesforce-app/define-your-business-requirements'
        },
        {
          title: 'General Data Structure',
          url: '/docs/cvent-salesforce-app/general-data-structure'
        },
        {
          title: 'Setup',
          url: 'x',
          items: [
            {
              title: 'Setup Overview',
              url: '/docs/cvent-salesforce-app/setup'
            },
            {
              title: 'Cvent-side Setup',
              url: '/docs/cvent-salesforce-app/cvent-side-setup'
            },
            {
              title: 'App Installation',
              url: '/docs/cvent-salesforce-app/app-installation'
            },
            {
              title: 'Use the Setup Wizard',
              url: '/docs/cvent-salesforce-app/use-the-setup-wizard'
            },
            {
              title: 'Configure the App Without the Wizard',
              url: '/docs/cvent-salesforce-app/configure-the-app-no-wizard',
              items: [
                {
                  title: 'Connect to Cvent',
                  url: '/docs/cvent-salesforce-app/connect-to-cvent'
                },
                {
                  title: 'Authorize Metadata API',
                  url: '/docs/cvent-salesforce-app/authorize-metadata-api'
                },
                {
                  title: 'Retrieve Cvent Custom Fields',
                  url: '/docs/cvent-salesforce-app/retrieve-cvent-custom-fields'
                },
                {
                  title: 'Sync Event Data',
                  url: '/docs/cvent-salesforce-app/sync-event-data'
                },
                {
                  title: 'Prepare for Attendee Data Sync',
                  url: '/docs/cvent-salesforce-app/prepare-for-attendee-data-sync'
                },
                {
                  title: 'Set Up Record Matching',
                  url: 'x',
                  items: [
                    {
                      title: 'Record Matching Overview',
                      url: '/docs/cvent-salesforce-app/record-matching-overview'
                    },
                    {
                      title: 'Configure Field Matching',
                      url: '/docs/cvent-salesforce-app/configure-field-matching'
                    },

                    {
                      title: 'Choose Record Matching Approach',
                      url: '/docs/cvent-salesforce-app/choose-record-matching-approach'
                    },
                    {
                      title: 'Configure Matching Rules',
                      url: '/docs/cvent-salesforce-app/configure-matching-rules'
                    },
                    {
                      title: 'Enforce Record Types',
                      url: '/docs/cvent-salesforce-app/enforce-record-types'
                    }
                  ]
                },
                {
                  title: 'Set Up Field Mappings',
                  url: '/docs/cvent-salesforce-app/set-up-field-mappings'
                },
                {
                  title: 'Sync Attendee Data',
                  url: '/docs/cvent-salesforce-app/sync-attendee-data'
                }
              ]
            },
            {
              title: 'Sharing App with Other Users',
              url: 'x',
              items: [
                {
                  title: 'App Roles and Permissions',
                  url: '/docs/cvent-salesforce-app/app-roles-and-permissions'
                },
                {
                  title: 'Customizing Page Layouts',
                  url: '/docs/cvent-salesforce-app/customizing-page-layouts'
                },
                {
                  title: 'Customizing Lightning Pages',
                  url: '/docs/cvent-salesforce-app/customizing-lightning-pages'
                },
                {
                  title: 'Event Visibility',
                  url: '/docs/cvent-salesforce-app/event-visibility'
                },
                {
                  title: 'Automatic Sync Settings',
                  url: '/docs/cvent-salesforce-app/automatic-sync-settings'
                },
                {
                  title: 'Purge Settings',
                  url: '/docs/cvent-salesforce-app/purge-settings'
                }
              ]
            }
          ]
        },
        {
          title: 'Attendee Management',
          url: 'x',
          items: [
            {
              title: 'Invite and Registration Workflows',
              url: '/docs/cvent-salesforce-app/invite-and-registration-workflows'
            },
            {
              title: 'Nominations',
              url: 'x',
              items: [
                {
                  title: 'Nominations Overview',
                  url: '/docs/cvent-salesforce-app/nominations-overview'
                },
                {
                  title: 'Configure Nomination Settings',
                  url: '/docs/cvent-salesforce-app/configure-nomination-settings'
                },
                {
                  title: 'Nomination Workflow',
                  url: '/docs/cvent-salesforce-app/nomination-workflow'
                },
                {
                  title: 'Managing Nomination Requests',
                  url: '/docs/cvent-salesforce-app/managing-nomination-requests'
                }
              ]
            },
            {
              title: 'Guests',
              url: '/docs/cvent-salesforce-app/guests'
            },
            {
              title: 'Attendee Activities',
              url: '/docs/cvent-salesforce-app/attendee-activities'
            },
            {
              title: 'Invitation Email Status',
              url: '/docs/cvent-salesforce-app/invitation-email-status'
            },
            {
              title: 'Event Questions',
              url: '/docs/cvent-salesforce-app/event-questions'
            },
            {
              title: 'Opt-Out Sync',
              url: '/docs/cvent-salesforce-app/opt-out-sync'
            },
            {
              title: 'Recommended Events',
              url: '/docs/cvent-salesforce-app/recommended-events'
            },
            {
              title: 'Real-time Attendee Sync',
              url: '/docs/cvent-salesforce-app/real-time-attendee-sync'
            },
            {
              title: 'Salesforce as an External Data Source',
              url: '/docs/cvent-salesforce-app/salesforce-as-an-external-data-source'
            }
          ]
        },
        {
          title: 'Return on Event',
          url: 'x',
          items: [
            {
              title: 'Campaigns',
              url: 'x',
              items: [
                {
                  title: 'Campaigns Overview',
                  url: '/docs/cvent-salesforce-app/campaigns-overview'
                },
                {
                  title: 'Event Campaign Configuration',
                  url: '/docs/cvent-salesforce-app/event-campaign-configuration'
                },
                {
                  title: 'Session Campaign Configuration',
                  url: '/docs/cvent-salesforce-app/session-campaign-configuration'
                },
                {
                  title: 'Manage Event Campaigns',
                  url: '/docs/cvent-salesforce-app/manage-event-campaigns'
                },
                {
                  title: 'Manage Session Campaigns',
                  url: '/docs/cvent-salesforce-app/manage-session-campaigns'
                }
              ]
            },
            {
              title: 'Reporting',
              url: '/docs/cvent-salesforce-app/reporting'
            }
          ]
        },
        {
          title: 'Process Automation',
          url: 'x',
          items: [
            {
              title: 'Tasks and Opportunities',
              url: '/docs/cvent-salesforce-app/tasks-and-opportunities'
            },
            {
              title: 'Using App Data in Workflows and Process Automation',
              url: 'x',
              items: [
                {
                  title: 'Workflows and Process Automation Overview',
                  url: '/docs/cvent-salesforce-app/using-app-data-workflows-automation'
                },
                {
                  title: 'Alert Cvent Event Admins on Cvent Log creation',
                  url: '/docs/cvent-salesforce-app/alerts-cvent-log-creation'
                },
                {
                  title: 'Alert Specific User on ATR failure',
                  url: '/docs/cvent-salesforce-app/alert-specific-user-on-atr-failure'
                },
                {
                  title: 'Show Cvent Events in Salesforce Calendar',
                  url: '/docs/cvent-salesforce-app/show-cvent-events-in-salesforce-calendar'
                },
                {
                  title: 'Push Alerts to Slack',
                  url: '/docs/cvent-salesforce-app/push-alerts-to-slack'
                }
              ]
            }
          ]
        },
        {
          title: 'Survey Workflows',
          url: 'x',
          items: [
            {
              title: 'Survey Program Overview',
              url: '/docs/cvent-salesforce-app/survey-program-overview'
            },
            {
              title: 'Create Survey Program',
              url: '/docs/cvent-salesforce-app/create-survey-program'
            },
            {
              title: 'Manage Survey Program',
              url: '/docs/cvent-salesforce-app/manage-survey-program'
            },
            {
              title: 'Setup Data Transfers',
              url: '/docs/cvent-salesforce-app/setup-data-transfers'
            },
            {
              title: 'Sync Respondent Data',
              url: '/docs/cvent-salesforce-app/sync-respondent-data'
            },
            {
              title: 'Survey Invitation Emails',
              url: '/docs/cvent-salesforce-app/survey-invitation-emails'
            }
          ]
        },
        {
          title: 'Quick Reference and Troubleshooting',
          url: 'x',
          items: [
            {
              title: 'Enabling Debug Mode',
              url: '/docs/cvent-salesforce-app/enabling-debug-mode'
            },
            {
              title: 'Migration Tool',
              url: '/docs/cvent-salesforce-app/migration-tool'
            },
            {
              title: 'Common errors with solutions',
              url: '/docs/cvent-salesforce-app/common-errors-with-solutions'
            },
            {
              title: 'Salesforce OAuth',
              url: '/docs/cvent-salesforce-app/salesforce-oauth'
            },
            {
              title: 'Salesforce Duplicate Rules Bypass',
              url: '/docs/cvent-salesforce-app/duplicate-bypass'
            },
            {
              title: 'Changing User for Data Sync',
              url: '/docs/cvent-salesforce-app/changing-user-for-data-sync'
            },
            {
              title: 'Past Dated Syncs',
              url: '/docs/cvent-salesforce-app/past-dated-syncs'
            },
            {
              title: 'Lead Conversion',
              url: '/docs/cvent-salesforce-app/lead-conversion'
            },
            {
              title: 'Multiple Currencies',
              url: '/docs/cvent-salesforce-app/multiple-currencies'
            },
            {
              title: 'Participation Substitution',
              url: '/docs/cvent-salesforce-app/participation-substitution'
            },
            {
              title: 'Person Accounts',
              url: '/docs/cvent-salesforce-app/person-accounts'
            },
            {
              title: 'Setting up a Newly Created Sandbox',
              url: '/docs/cvent-salesforce-app/setting-up-a-newly-created-sandbox'
            },
            {
              title: 'Shield Encryption',
              url: '/docs/cvent-salesforce-app/shield-encryption'
            },
            {
              title: 'Understanding Attendee and Event Statuses',
              url: '/docs/cvent-salesforce-app/understanding-attendee-and-event-statuses'
            },
            {
              title: 'Upgrading from 5.0 or below',
              url: '/docs/cvent-salesforce-app/upgrade-from-5-or-lower'
            },
            {
              title: 'Uninstalling the App',
              url: '/docs/cvent-salesforce-app/uninstalling-the-app'
            },
            {
              title: 'Upgrading the App',
              url: '/docs/cvent-salesforce-app/upgrading-the-app'
            },
            {
              title: 'Upgrading Salesforce App for Winter 24',
              url: '/docs/cvent-salesforce-app/upgrade-winter-24'
            }
          ]
        }
      ]
    },
    {
      title: 'Webhooks',
      items: [
        {
          url: '/docs/webhooks/overview',
          title: 'Overview'
        },
        {
          url: '/docs/webhooks/technical-requirements',
          title: 'Technical Requirements'
        },
        {
          url: '/docs/webhooks/account-setup',
          title: 'Account Setup'
        },
        {
          url: '/docs/webhooks/event-setup',
          title: 'Event Setup'
        },
        {
          url: '/docs/webhooks/webhooks-reports',
          title: 'Webhook Reports'
        },
        {
          url: '/docs/webhooks/messages',
          title: 'JSON Messages',
          items: [
            {
              url: '/docs/webhooks/messages/attendee-emails',
              title: 'Attendee Emails',
              items: [
                {
                  url: '/docs/webhooks/messages/attendee-emails/added-to-invitation-list',
                  title: 'Invitee/Guest Added to Invitation List'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/declines-invitation',
                  title: 'Invitee Declines Invitation'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/email-bounces',
                  title: 'Invitee/Guest Email Bounces'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/email-opened',
                  title: 'Invitee/Guest Email Opened'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/email-sent',
                  title: 'Invitee/Guest Email Sent'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/resubscribes-to-event-email',
                  title: 'Invitee/Guest Resubscribes to Event Email'
                },
                {
                  url: '/docs/webhooks/messages/attendee-emails/unsubscribed-from-event-email',
                  title: 'Invitee/Guest Unsubscribed from Event Email'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/attendees',
              title: 'Attendees',
              items: [
                {
                  url: '/docs/webhooks/messages/attendees/abandons-registration',
                  title: 'Invitee Abandons Registration'
                },
                {
                  url: '/docs/webhooks/messages/attendees/registration-cancelled',
                  title: 'Invitee/Guest Event Registration Cancelled'
                },
                {
                  url: '/docs/webhooks/messages/attendees/marked-as-no-show',
                  title: 'Invitee/Guest Marked as Event No-Show'
                },
                {
                  url: '/docs/webhooks/messages/attendees/marked-session-no-show',
                  title: 'Invitee Marked as Session No-Show'
                },
                {
                  url: '/docs/webhooks/messages/attendees/marked-event-participant',
                  title: 'Invitee/Guest Marked as Event Participant'
                },
                {
                  url: '/docs/webhooks/messages/attendees/marked-session-participant',
                  title: 'Invitee/Guest Marked as Session Participant'
                },
                {
                  url: '/docs/webhooks/messages/attendees/registered-for-event',
                  title: 'Invitee/Guest Registered for Event'
                },
                {
                  url: '/docs/webhooks/messages/attendees/registered-for-session',
                  title: 'Invitee/Guest Registered for Session'
                },
                {
                  url: '/docs/webhooks/messages/attendees/registration-modified',
                  title: 'Invitee/Guest Event Registration Modified'
                },
                {
                  url: '/docs/webhooks/messages/attendees/session-registration-cancelled',
                  title: 'Invitee/Guest Event Registration Cancelled'
                },
                {
                  url: '/docs/webhooks/messages/attendees/substituted-into-event-registration',
                  title: 'Invitee Substituted into Event Registration'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/contacts',
              title: 'Contacts',
              items: [
                {
                  url: '/docs/webhooks/messages/contacts/confirm-email-opt-in',
                  title: 'Contact Confirms Email Opt-In'
                },
                {
                  url: '/docs/webhooks/messages/contacts/contact-created',
                  title: 'Contact Created'
                },
                {
                  url: '/docs/webhooks/messages/contacts/contact-deleted',
                  title: 'Contact Deleted'
                },
                {
                  url: '/docs/webhooks/messages/contacts/contact-edited',
                  title: 'Contact Edited'
                },
                {
                  url: '/docs/webhooks/messages/contacts/opts-back-in-to-emails',
                  title: 'Contact Opts Back in to Emails'
                },
                {
                  url: '/docs/webhooks/messages/contacts/contact-opts-out-of-emails',
                  title: 'Contact Opts out of Emails'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/event-sessions',
              title: 'Event Sessions',
              items: [
                {
                  url: '/docs/webhooks/messages/event-sessions/session-created',
                  title: 'Session Created'
                },
                {
                  url: '/docs/webhooks/messages/event-sessions/session-deleted',
                  title: 'Session Deleted'
                },
                {
                  url: '/docs/webhooks/messages/event-sessions/session-deleted',
                  title: 'Session Modified'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/event-speakers',
              title: 'Event Speakers',
              items: [
                {
                  url: '/docs/webhooks/messages/event-speakers/speaker-created',
                  title: 'Speaker Created'
                },
                {
                  url: '/docs/webhooks/messages/event-speakers/speaker-deleted',
                  title: 'Speaker Deleted'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/events',
              title: 'Events',
              items: [
                {
                  url: '/docs/webhooks/messages/events/event-cancelled',
                  title: 'Event Cancelled'
                },
                {
                  url: '/docs/webhooks/messages/events/event-deleted',
                  title: 'Event Deleted'
                },
                {
                  url: '/docs/webhooks/messages/events/event-modified',
                  title: 'Event Modified'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/meeting-requests',
              title: 'Meeting Requests',
              items: [
                {
                  url: '/docs/webhooks/messages/meeting-requests/meeting-request-modified',
                  title: 'Meeting Request Modified'
                },
                {
                  url: '/docs/webhooks/messages/meeting-requests/meeting-request-submitted',
                  title: 'Meeting Request Submitted'
                }
              ]
            },
            {
              url: '/docs/webhooks/messages/manual',
              title: 'Manual Syncs',
              items: [
                {
                  url: '/docs/webhooks/messages/manual/manual-attendee',
                  title: 'Attendee Manually Synced'
                },
                {
                  url: '/docs/webhooks/messages/manual/manual-attendee-abandoned',
                  title: 'Invitee Abandoned Registration Manually Synced'
                },
                {
                  url: '/docs/webhooks/messages/manual/manual-event',
                  title: 'Event Manually Synced'
                },
                {
                  url: '/docs/webhooks/messages/manual/manual-session',
                  title: 'Session Manually Synced'
                },
                {
                  url: '/docs/webhooks/messages/manual/manual-speaker',
                  title: 'Speaker Manually Synced'
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  const side: NavProps = sideNavigationContent;

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    side
  };
};
